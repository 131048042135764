import React, {useEffect, useState} from "react";
import Footer from "../../../component/footer/footer";
import {callApiMaestrosAddBusqueda, callApiMaestrosVerBusqueda} from "../../../core/restUtils";
import "./busqueda.css";
import MenuAdministracion from "../../../component/menu/administrador/menu";

export default function AdministrarBusquedaAdmin() {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingAdd, setLoadingAdd] = useState(true);
    const [error, setError] = useState(null);
    const [values, setValues] = React.useState({
        bop: "",
        textoBusqueda: "",
        prioridad: null,
        indexable: true
    });

    useEffect(() => {
        setLoadingAdd(false);
        setLoading(true);
        getBusquedaConfiguracion();

    }, []);

    function handleChange(evt) {
        /*
          evt.target es el elemento que ejecuto el evento
          name identifica el input y value describe el valor actual
        */
        const { target } = evt;
        const { name, value } = target;

        /*
          Este snippet:
          1. Clona el estado actual
          2. Reemplaza solo el valor del
             input que ejecutó el evento
        */
        const newValues = {
            ...values,
            [name]: value,
        };

        // Sincroniza el estado de nuevo
        setValues(newValues);
    }

    function handleSubmit(evt) {
        /*
          Previene el comportamiento default de los
          formularios el cual recarga el sitio
        */
        evt.preventDefault();

        const setBusqueda = async () => {
            try {
                setLoadingAdd(true);
                await callApiMaestrosAddBusqueda(values.bop, values.textoBusqueda.toUpperCase(), values.prioridad, values.indexable);
            } catch (error) {
                setError(error);
            } finally {
                setLoadingAdd(false);
            }
        };
        setBusqueda();
    }

    const getBusquedaConfiguracion = async () => {
        try {
            setLoading(true);
            const result = await callApiMaestrosVerBusqueda()
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const renderConfigBusqueda = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        return posts.map((item, index) => (
            <>
                <ul>
                    <div className="busqueda" key={index}>
                        <h3>BOP: {item.bop}</h3>
                        <h3>Texto Busqueda: {item.textoBusqueda}</h3>
                        <h3>Prioridad: {item.prioridad}</h3>
                        <h3>Indexable: {item.indexable ? 'SI' : 'NON'}</h3>
                        <p>UUID: {item.uuidEntidad}</p>
                    </div>
                </ul>
            </>
        ));

    }

    const renderAddConfigBusqueda = () => {

        if (loadingAdd) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

            return (
                <>
                    <div>
                        <form onSubmit={handleSubmit}>
                            <h1>Configurar nuevo filtrado</h1>
                            <div className="crearFiltrado" key="crearFiltradoId">

                                <label htmlFor="bop">Bop:</label>
                                <input type="text" name="bop" id="bop" value={values.bop} onChange={handleChange}/>

                                <label htmlFor="textoBusqueda">Texto Busqueda:</label>
                                <input type="text" name="textoBusqueda" id="textoBusqueda" value={values.textoBusqueda} onChange={handleChange}/>

                                <label htmlFor="prioridad">Prioridad:</label>
                                <input type="number" name="prioridad" id="prioridad" value={values.prioridad} onChange={handleChange}/>

                                <label htmlFor="indexable">Indexable:</label>
                                <select name="indexable" id="indexable"  value={values.indexable} onChange={handleChange}>
                                    <option value="true">SI</option>
                                    <option value="false">NON</option>
                                </select>

                                <button type="submit">Engadir</button>
                            </div>
                        </form>
                    </div>
                </>
    );

    }


    return (
        <>
            {<MenuAdministracion/>}
            <div className="content">
                {renderAddConfigBusqueda()}
                <h1>Listado de Busquedas</h1>
                {renderConfigBusqueda()}
            </div>
            {<Footer/>}
        </>
    );

}