import React, {useEffect, useState} from "react";
import Footer from "../../../component/footer/footer";
import {callApiMaestrosAddEtiqueta, callApiMaestrosVerEtiquetas} from "../../../core/restUtils";
import "./etiquetas.css";
import MenuAdministracion from "../../../component/menu/administrador/menu";

export default function AdministrarEtiquetasAdmin() {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingAdd, setLoadingAdd] = useState(false);
    const [error, setError] = useState(null);
    const [values, setValues] = React.useState({
        etiqueta: "",
        textoBusqueda: "",
        prioridad: null,
        comprobarCadenaCompleta: true
    });

    useEffect(() => {
        setLoading(true);
        getEtiquetasConfiguracion();
    }, []);

    const getEtiquetasConfiguracion = async () => {
        try {
            setLoading(true);
            const result = await callApiMaestrosVerEtiquetas()
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    function handleChange(evt) {
        /*
          evt.target es el elemento que ejecuto el evento
          name identifica el input y value describe el valor actual
        */
        const { target } = evt;
        const { name, value } = target;

        /*
          Este snippet:
          1. Clona el estado actual
          2. Reemplaza solo el valor del
             input que ejecutó el evento
        */
        const newValues = {
            ...values,
            [name]: value,
        };

        // Sincroniza el estado de nuevo
        setValues(newValues);
    }

    function handleSubmit(evt) {
        /*
          Previene el comportamiento default de los
          formularios el cual recarga el sitio
        */
        evt.preventDefault();

        const setEtiqueta = async () => {
            try {
                setLoadingAdd(true);
                await callApiMaestrosAddEtiqueta(values.etiqueta, values.textoBusqueda.toLowerCase(), values.prioridad, values.comprobarCadenaCompleta);
                // Limpiar form
                setValues({
                    etiqueta: "",
                    textoBusqueda: "",
                    prioridad: null,
                    comprobarCadenaCompleta: true
                });
            } catch (error) {
                setError(error);
            } finally {
                setLoadingAdd(false);
            }
        };
        setEtiqueta();
        getEtiquetasConfiguracion();
    }

    const renderAddConfigEtiquetas = () => {

        if (loadingAdd) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        return (
            <>
                <div>
                    <form onSubmit={handleSubmit}>
                        <h1>Configurar nuevas etiquetas</h1>
                        <div className="crearFiltrado" key="crearFiltradoId">

                            <label htmlFor="etiqueta">Etiqueta:</label>
                            <input type="text" name="etiqueta" id="etiqueta" value={values.etiqueta} onChange={handleChange}/>

                            <label htmlFor="textoBusqueda">Texto Busqueda:</label>
                            <input type="text" name="textoBusqueda" id="textoBusqueda" value={values.textoBusqueda} onChange={handleChange}/>

                            <label htmlFor="prioridad">Prioridad:</label>
                            <input type="number" name="prioridad" id="prioridad" value={values.prioridad} onChange={handleChange}/>

                            <label htmlFor="comprobarCadenaCompleta">Comprobar cadena Completa:</label>
                            <select name="comprobarCadenaCompleta" id="comprobarCadenaCompleta"  value={values.comprobarCadenaCompleta} onChange={handleChange}>
                                <option value="true">SI</option>
                                <option value="false">NON</option>
                            </select>

                            <button type="submit">Engadir</button>
                        </div>
                    </form>
                </div>
            </>
        );

    }

    const renderConfigEtiquetas = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        return posts.map((item, index) => (
            <>
                <ul id={index}>
                    <div className="busqueda" key={item.uuidEntidad}>
                        <h3>Etiqueta: {item.etiqueta}</h3>
                        <h3>Texto Busqueda: {item.textoBusqueda}</h3>
                        <h3>Prioridad: {item.prioridad}</h3>
                        <h3>Comprobar cadena completa: {item.comprobarCadenaCompleta ? 'SI' : 'NON'}</h3>
                        <p>UUID: {item.uuidEntidad}</p>
                    </div>
                </ul>
            </>
        ));

    }

    return (
        <>
            {<MenuAdministracion/>}
            <div className="content">
                {renderAddConfigEtiquetas()}
                {renderConfigEtiquetas()}
            </div>
            {<Footer/>}
        </>
    );

}