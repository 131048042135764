import React, {useEffect, useState} from "react";
import {callApiOposConsultarBops, callApiOposConsultarWebsBopsFechaActual} from "../../core/restUtils";
import Menu from "../../component/menu/menu";
import Footer from "../../component/footer/footer";
import './bop.css';
import {formatDateConGuion, getCurrentDate} from "../../core/utiles";

export default function Bop() {

    const [posts, setPosts] = useState([]);
    const [webs, setWebs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        getDataBop();
        getUrlWebBops(getCurrentDate());
    }, []);

    const getDataBop = async () => {
        try {
            const result = await callApiOposConsultarBops()
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const getUrlWebBops = async (date) => {
        try {
            const result = await callApiOposConsultarWebsBopsFechaActual(date)
            setWebs(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const renderWebsBop = () => {

        return webs.map((item, index) => {

            return (
                <>
                    <a href={item.url} rel="noopener" target="_blank">
                        <div className="bop" key={index}>
                            {item.bop}
                        </div>
                    </a>
                </>
            )
        });
    };

    const renderBop = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="loading.gif" height="100" width="100"/></p></div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        return posts.map((item, index) => {

            // Formatear a data
            const fechaFormateada = formatDateConGuion(item.fechaBop[2], item.fechaBop[1], item.fechaBop[0])

            return (
                <>
                    <a href={'/public/?fecha=' + fechaFormateada + '&bop=' + item.bop}>
                        <div className="bop" key={index}>
                            {item.bop} ({fechaFormateada})
                        </div>
                    </a>
                </>
            )
        });
    };

    return (
        <>
            {<Menu/>}
            <div className="bop-container">
                <h2>Abrir WEB BOPs</h2>
                {renderWebsBop()}
            </div>
            <div className="bop-container">
                <h2>Listado BOPs</h2>
                {renderBop()}
            </div>
            {<Footer/>}
        </>
    );
}