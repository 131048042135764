export const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const formatDate = (dia, mes, ano) => {
    // Convertir el array a un objeto Date
    const fecha = new Date(ano, mes - 1, dia);

    // Formatear la fecha manualmente
    const options = {year: 'numeric', month: 'long', day: 'numeric'};
    return fecha.toLocaleDateString('es-ES', options);
}

export const formatDateConGuion = (dia, mes, ano) => {
    // Convertir el array a un objeto Date
    const fecha = new Date(ano, mes - 1, dia);

    // Formatear la fecha a yyyy-MM-dd
    const year = fecha.getFullYear();
    const month = String(fecha.getMonth() + 1).padStart(2, '0'); // Meses empiezan desde 0
    const day = String(fecha.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const formatDateTimeConGuion = (dia, mes, ano, hora, minuto) => {
    // Convertir el array a un objeto Date
    const fecha = new Date(ano, mes - 1, dia, hora, minuto);

    // Formatear la fecha a yyyy-MM-dd
    const year = fecha.getFullYear();
    const month = String(fecha.getMonth() + 1).padStart(2, '0'); // Meses empiezan desde 0
    const day = String(fecha.getDate()).padStart(2, '0');
    return `${year}-${month}-${day} ${hora}:${minuto}`;
}