// Crear un contexto para la autenticación
import {
    SESION_ADMIN_PERMITIDO,
    SESION_RECARGA_ID, SESION_REDIRECT_SESION,
    SESION_TIME_TOKEN_JWT,
    SESION_TOKEN_JWT,
    SESION_TOKEN_REFRESCO_JWT,
    URL_ADMIN_HOME,
    URL_HOME
} from "../core/constantes";
import {createContext, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {callApiImpectionOpos, callApiInstrospeccion, callApiToken} from "../core/restUtils";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem(SESION_TOKEN_JWT) || "");

    const loginAction = async (code) => {
        try {
            // Enviar peticion token
            const result = await callApiToken(code)
            if (result.access_token) {
                console.log(result)
                localStorage.setItem(SESION_TOKEN_JWT, result.access_token);
                localStorage.setItem(SESION_TOKEN_REFRESCO_JWT, result.refresh_token);
                localStorage.setItem(SESION_TIME_TOKEN_JWT, result.expires_in);
                localStorage.setItem(SESION_ADMIN_PERMITIDO, true);

                // Comprobar token
                const resultIntrospeccion = await callApiInstrospeccion(result.access_token)
                // Comprobar resultado
                if (!resultIntrospeccion.active) {
                    localStorage.removeItem(SESION_TIME_TOKEN_JWT);
                    localStorage.removeItem(SESION_TOKEN_JWT);
                    localStorage.removeItem(SESION_TOKEN_REFRESCO_JWT);
                    throw new Error("Error endpoint Token");
                } else {
                    const resultadoToken = await callApiImpectionOpos();
                    setUser(resultIntrospeccion.name);
                    setToken(result.access_token);
                    navigate(URL_ADMIN_HOME);
                    return;
                }


            } else {
                throw new Error("Error endpoint Token");
            }
        } catch (err) {
            console.error(err);
        }
    };

    const comprobarSesionAction = async () => {
        try {
            // Comprobamos sesion
            console.log("Consultando callApiImpectionOpos")
            await callApiImpectionOpos();
        } catch (err) {
            console.error("Error al consultar callApiImpectionOpos");
            logOut();
        }
    };


    const logOut = () => {
        // Eliminar refresco
        clearInterval(localStorage.getItem(SESION_RECARGA_ID));
        localStorage.removeItem(SESION_TIME_TOKEN_JWT);
        localStorage.removeItem(SESION_TOKEN_JWT);
        localStorage.removeItem(SESION_TOKEN_REFRESCO_JWT);
        localStorage.removeItem(SESION_RECARGA_ID);
        localStorage.removeItem(SESION_REDIRECT_SESION);
        navigate(URL_HOME);
    };

    return (
        <AuthContext.Provider value={{ token, user, loginAction, comprobarSesionAction, logOut }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};