import './menu.css';
import {useEffect, useState} from "react";
import {SESION_ADMIN_PERMITIDO} from "../../core/constantes";
export default function Menu() {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isActivoMenuAdmin, setIsActivoMenuAdmin] = useState(false);

    useEffect(() => {

        // Activar menu con cookie
        var activo = localStorage.getItem(SESION_ADMIN_PERMITIDO);
        if (activo) {
            setIsActivoMenuAdmin(true);
        }

    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <header>
                <h1>Oposicions Galicia</h1>
                <nav>
                    <a onClick={toggleMenu} id="menu-toggle">☰</a>
                    <ul id="menu" className={isMenuOpen ? 'visible' : 'hidden'}>
                        <li><a href="/public/home">Inicio</a></li>
                        <li><a href="/public/etiquetas">Ver etiquetas</a></li>
                        <li><a href="/public/bop">Ver bops</a></li>
                        <li><a href="https://t.me/OposicionsGalicia" target="_blank" rel="noreferrer">Canle Telegram</a></li>
                        <li><a href="#contact">Contacto</a></li>
                        {isActivoMenuAdmin
                            ? <li><a href="/public/admin/login">Login Administracion</a></li>
                            : null
                        }
                    </ul>
                </nav>
            </header>
        </>
    );
}