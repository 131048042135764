import './footer.css';

export default function Footer() {

    return (
        <footer>
            <p>&copy; 2024 Oposicions Galicia. Todos os dereitos reservados.</p>
            <div className="social-icons">
               <a href="https://t.me/OposicionsGalicia" rel="noopener" target="_blank">Canle Telegram</a>
            </div>
        </footer>
    );
}
