import React, {useEffect, useState} from "react";
import {formatDate} from "../../../core/utiles";
import MenuAdministracion from "../../../component/menu/administrador/menu";
import Footer from "../../../component/footer/footer";
import {
    callApiAdministrarEliminarFaseProcesoOpo,
    callApiOposConsultaProcesosOposAdmin, callApiOposConsultaProcesosOposEnProcesoAdmin,
    callApiOposConsultaProcesosOposFinalizadosAdmin
} from "../../../core/restUtils";
import {URL_ADMIN_ADD_PASO_PROCESO_OPO, URL_ADMIN_MODIFICAR_PASO_PROCESO_OPO, URL_ADMIN_VER_PROCESO_OPO} from "../../../core/constantes";
import {useNavigate} from "react-router-dom";

export default function AdministrarVerListadoProcesoOposAdmin() {

    const [posts, setPosts] = useState([]);
    const [procesosFinalizados, setProcesosFinalizados] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        getDataProcesosOpos()
        getDataProcesosOposFinalizados()
        setLoading(false);
    }, []);

    const getDataProcesosOpos = async () => {
        try {
            setLoading(true);
            const result = await callApiOposConsultaProcesosOposEnProcesoAdmin(0, 30)
            setPosts(result)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const getDataProcesosOposFinalizados = async () => {
        try {
            setLoading(true);
            const result = await callApiOposConsultaProcesosOposFinalizadosAdmin(0, 30)
            setProcesosFinalizados(result)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const eliminarDataRLProcesosOpos = async (uuidProcesoRlOpo) => {
        try {
            setLoading(true);
            await callApiAdministrarEliminarFaseProcesoOpo(uuidProcesoRlOpo)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    function handleEliminarSubmit(evt) {
        /*
          Previene el comportamiento default de los
          formularios el cual recarga el sitio
        */
        evt.preventDefault();

        // Consultar uuid
        const buttonValue = evt.target.querySelector('button[type="submit"]').value;

        eliminarDataRLProcesosOpos(buttonValue);

        alert("Eliminada relacion")

        // Recargar
        getDataProcesosOpos()

    }

    function handleModificarProcesoSubmit(evt) {
        /*
          Previene el comportamiento default de los
          formularios el cual recarga el sitio
        */
        evt.preventDefault();

        // Consultar uuid
        const buttonValue = evt.target.querySelector('button[type="submit"]').value;

        navigate(URL_ADMIN_MODIFICAR_PASO_PROCESO_OPO+buttonValue);

    }

    function handleModificarSubmit(evt) {
        /*
          Previene el comportamiento default de los
          formularios el cual recarga el sitio
        */
        evt.preventDefault();

        // Consultar uuid
        const buttonValue = evt.target.querySelector('button[type="submit"]').value;

        navigate(URL_ADMIN_ADD_PASO_PROCESO_OPO+buttonValue);

    }

    const renderProcesoOposEnProceso = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        return posts.map((item, index) => {
            return renderProcesoOpos(item, index);
        });
    };

    const renderProcesoOposFinalizados = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        return procesosFinalizados.map((item, index) => {
            return renderProcesoOpos(item, index);
        });
    };

    const renderProcesoOpos = (item, index) => {
        // Formatear a data
        const fechaFormateada = formatDate(item.fechaBopInicioProceso[2], item.fechaBopInicioProceso[1], item.fechaBopInicioProceso[0])
        const urlInterna = "/public/oposicion/" + item.urlAmigable;

        return (
            <>
                <ul>
                    <div className="oposicion" key={index}>
                        <h2>Oposición: {item.organizacion}</h2>
                        <h3>Fecha BOP: {fechaFormateada}</h3>
                        <h3>BOP: {item.bop}</h3>
                        <h3>UUID: {item.uuidEntidad}</h3>
                        <h3>Mostrable: {item.procesoMostrable ? 'SI' : 'NO'}</h3>
                        <h3>Finalizado: {item.procesoFinalizado ? 'SI' : 'NO'}</h3>
                        <h3>Número visitas: {item.numeroVisitas}</h3>
                        <p><strong>Titulo:</strong> {item.titulo}</p>
                        <p><strong>Descripción:</strong> {item.descripcion}</p>
                        <p><a href={urlInterna} rel="noopener" target="_blank">Más información</a></p>
                        <div className="containeretiquetas">
                            {item.procesosOposicionesList.map((eti, index) => (
                                <div className="etiquetas">
                                    <p>Titulo: {eti.titulo} - Orden: {eti.orden} - Fecha: {eti.fechaBop}</p>
                                    <form onSubmit={handleEliminarSubmit}>
                                        <button type="submit" value={eti.uuid}>Eliminar</button>
                                    </form>
                                </div>
                            ))}
                        </div>
                        <div className="oposForms">
                            <form onSubmit={handleModificarSubmit}>
                                <button type="submit" value={item.uuidEntidad}>Añadir paso a Proceso</button>
                            </form>
                            <form onSubmit={handleModificarProcesoSubmit}>
                                <button type="submit" value={item.uuidEntidad}>Modificar Proceso</button>
                            </form>
                        </div>
                    </div>
                </ul>
            </>
        );
    };

    return (
        <>
            {<MenuAdministracion/>}
            <div className="content">
                <h1>Listado de proceso de Oposicion</h1>
                <h2 className="separador">EN PROCESO</h2>
                {renderProcesoOposEnProceso()}
                <br/>
                <h2 className="separador">FINALIZADOS</h2>
                {renderProcesoOposFinalizados()}
            </div>
            {<Footer/>}
        </>
    );

}