import React, {useEffect, useState} from "react";
import Menu from "../../../component/menu/menu";
import Footer from "../../../component/footer/footer";
import {useAuth} from "../../../auth/AuthProvider";

export default function CerrarSesionAdmin() {

    const [loading, setLoading] = useState(true);
    const auth = useAuth();

    useEffect(() => {
        setLoading(true);
        auth.logOut()
    }, []);

    const renderCerrarSesion = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="loading.gif" height="100" width="100"/></p></div>;

    }

    return (
        <>
            {<Menu/>}
            {renderCerrarSesion()}
            {<Footer/>}
        </>
    );

}