import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import {useAuth} from "./AuthProvider";

const PrivateRoute = () => {
    const user = useAuth();
    if (!user.token) return <Navigate to="/public/admin/login" />;
    console.log("Temos token en sesion e pasamos por Private Route");
    user.comprobarSesionAction();
    return <Outlet />;
};

export default PrivateRoute;
