import React, {useEffect, useState} from "react";
import {callApiAdministrarVerEstadisticas} from "../../../core/restUtils";
import {formatDateConGuion, formatDateTimeConGuion} from "../../../core/utiles";
import MenuAdministracion from "../../../component/menu/administrador/menu";
import Footer from "../../../component/footer/footer";

export default function AdministrarEstadisticasAdmin() {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        getEstadisticas();
    }, []);

    const getEstadisticas = async () => {
        try {
            setLoading(true);
            const result = await callApiAdministrarVerEstadisticas(0, 30)
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const renderEstadisticas = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p>
        </div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        return posts.map((item, index) => {

            // Formatear a data
            const fechaFormateada = formatDateTimeConGuion(item.fechaCreacion[2], item.fechaCreacion[1], item.fechaCreacion[0], item.fechaCreacion[3], item.fechaCreacion[4])
            const fechaFormateadaBop = formatDateConGuion(item.fechaBop[2], item.fechaBop[1], item.fechaBop[0])

            return (
                <>
                    <ul>
                        <div className="oposicion" key={index}>
                            <h2>{item.bop} ({fechaFormateadaBop})</h2>
                            <h3>UUID: {item.uuidEntidad}</h3>
                            <h3>Fecha Envio: {fechaFormateada}</h3>
                            <h3>Trazas: {item.trazas}</h3>
                            <h3>Edictos Analizados: {item.numeroTotalEdictosAnalizadas}</h3>
                            <h3>Edictos Opos: {item.numeroEdictosOposAnalizadas}</h3>
                            <div className="containeredictoslog">
                                {item.edictosList.map((logedictos, index) => (
                                    <p>{logedictos}</p>
                                ))}
                            </div>
                        </div>
                    </ul>
                </>
            )
        });
    };

    return (
        <>
            {<MenuAdministracion/>}
            <div className="content">
                <h1>Listado de Estadisticas</h1>
                {renderEstadisticas()}
            </div>
            {<Footer/>}
        </>
    );

}