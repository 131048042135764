import React, {useEffect, useState} from "react";
import {callApiAdministrarModificarProcesoOpo, callApiProcesoOposicionConsultaPorUuidOpoAdmin} from "../../../core/restUtils";
import MenuAdministracion from "../../../component/menu/administrador/menu";
import Footer from "../../../component/footer/footer";

export default function AdministrarModificarProcesoOposicionAdmin() {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [values, setValues] = React.useState({
        urlAmigable: "",
        organizacion: "",
        urlImagen: "",
        titulo: "",
        descripcion: "",
        tipoContrato: "",
        sistemaSeleccion: "",
        uuidOpoInicio: "",
        procesoFinalizado: "false",
        procesoMostrable: "true"
    });

    useEffect(() => {
        setLoading(true)

        // recuperamos el querystring
        const querystring = window.location.search
        console.log(querystring)

        // usando el querystring, creamos un objeto del tipo URLSearchParams
        const params = new URLSearchParams(querystring)
        console.log(params)

        if (params != null && params.get("uuid") != null) {
            console.log("Buscado Proceso Opo por uuid")
            getDataProcesoOposPorUuid(params.get("uuid"));
        }

        setLoading(false)
    }, []);

    const getDataProcesoOposPorUuid = async (uuid) => {
        try {
            setLoading(true);
            const result = await callApiProcesoOposicionConsultaPorUuidOpoAdmin(uuid)
            if (result != null) {
                // Asignar valores
                setValues({
                    urlAmigable: result.urlAmigable,
                    organizacion: result.organizacion,
                    urlImagen: result.urlImagen,
                    titulo: result.titulo,
                    descripcion: result.descripcion,
                    sistemaSeleccion: result.sistemaSeleccion,
                    tipoContrato: result.tipoContrato,
                    procesoFinalizado: result.procesoFinalizado,
                    procesoMostrable: result.procesoMostrable
                });
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const modificarProcesoOpo = async (urlAmigable, organizacion, urlImagen, titulo, descripcion, sistemaSeleccion, tipoContrato, uuidOpoInicio, procesoFinalizado, procesoMostrable) => {
        try {
            setLoading(true);
            await callApiAdministrarModificarProcesoOpo(urlAmigable, organizacion, urlImagen, titulo, descripcion, sistemaSeleccion, tipoContrato, uuidOpoInicio, procesoFinalizado, procesoMostrable);
        } catch (error) {
            alert(error.descripcion)
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    function handleChange(evt) {
        /*
          evt.target es el elemento que ejecuto el evento
          name identifica el input y value describe el valor actual
        */
        const { target } = evt;
        const { name, value } = target;

        /*
          Este snippet:
          1. Clona el estado actual
          2. Reemplaza solo el valor del
             input que ejecutó el evento
        */
        const newValues = {
            ...values,
            [name]: value,
        };

        // Sincroniza el estado de nuevo
        setValues(newValues);
    }

    function handleSubmit(evt) {
        /*
          Previene el comportamiento default de los
          formularios el cual recarga el sitio
        */
        evt.preventDefault();

        modificarProcesoOpo(
            values.urlAmigable, values.organizacion, values.urlImagen, values.titulo, values.descripcion,
            values.sistemaSeleccion, values.tipoContrato, values.uuidOpoInicio, values.procesoFinalizado, values.procesoMostrable)

        alert("Opo modificada correctamente")

        setLoading(true);
    }

    const renderFormularioModificacionProceso = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        return (
            <>
                <form onSubmit={handleSubmit}>
                    <h2>Modificar proceso OPO</h2>
                    <div className="modificarOpo" key="modificarOpoId">

                        <label htmlFor="urlAmigableId">URL Amigable:</label>
                        <input type="text" name="urlAmigable" id="urlAmigableId" value={values.urlAmigable} onChange={handleChange}/>

                        <label htmlFor="organizacionId">Organización:</label>
                        <input type="text" name="organizacion" id="organizacionId" value={values.organizacion} onChange={handleChange}/>

                        <label htmlFor="descripcionId">Descripcion:</label>
                        <input type="text" name="descripcion" id="descripcionId" value={values.descripcion} onChange={handleChange}/>

                        <label htmlFor="tituloId">Titulo:</label>
                        <input type="text" name="titulo" id="tituloId" value={values.titulo} onChange={handleChange}/>

                        <label htmlFor="urlImagenId">URL Imagen:</label>
                        <input type="text" name="urlImagen" id="urlImagenId" value={values.urlImagen} onChange={handleChange}/>

                        <label htmlFor="sistemaSeleccionId">Sistema selección:</label>
                        <input type="text" name="sistemaSeleccion" value={values.sistemaSeleccion} id="sistemaSeleccionId"
                               onChange={handleChange}/>

                        <label htmlFor="tipoContratoId">Tipo Contrato:</label>
                        <input type="text" name="tipoContrato" id="tipoContratoId" value={values.tipoContrato}
                               onChange={handleChange}/>

                        <label htmlFor="procesoFinalizadoId">Proceso finalizado:</label>
                        <select name="procesoFinalizado" id="procesoFinalizadoId" onChange={handleChange}>
                            <option value="false">NON</option>
                            <option value="true">SI</option>
                        </select>

                        <label htmlFor="procesoMostrableId">Indexable:</label>
                        <select name="procesoMostrable" id="procesoMostrableId" onChange={handleChange}>
                            <option value="true">SI</option>
                            <option value="false">NON</option>
                        </select>

                        <button type="submit">Modificar</button>
                    </div>
                </form>
            </>
        )
    };

    return (
        <>
            {<MenuAdministracion/>}
            <div className="content">
                <h1>Administrar Opo</h1>
                {renderFormularioModificacionProceso()}
            </div>
            {<Footer/>}
        </>
    );

}