import React, {useEffect, useRef, useState} from "react";
import Menu from "../../../component/menu/menu";
import Footer from "../../../component/footer/footer";
import {useNavigate} from 'react-router-dom';
import {URL_ADMIN_HOME_LOGIN} from "../../../core/constantes";
import {useAuth} from "../../../auth/AuthProvider";

export default function CallbackAdminLogin() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const user = useAuth();

    useEffect(() => {

        // recuperamos el querystring
        const querystring = window.location.search
        console.log(querystring)

        // usando el querystring, creamos un objeto del tipo URLSearchParams
        const params = new URLSearchParams(querystring)
        console.log(params)

        if (params != null && params.get("code") != null) {
            const code = params.get("code");
            // Enviar peticion token
            user.loginAction(code);
        } else {
            console.log(`Redirigir a Home por Login - ${window.location.origin}`)
            navigate(URL_ADMIN_HOME_LOGIN);
        }


    }, []);

    const renderCallback = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return navigate(URL_ADMIN_HOME_LOGIN);

    }

    return (
        <>
            {<Menu/>}
                {renderCallback()}
            {<Footer/>}
        </>
    );

}
