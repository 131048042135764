import React, {useEffect, useState} from "react";
import Footer from "../../../component/footer/footer";
import {SESION_REDIRECT_SESION, URL_ADMIN_HOME_LOGIN} from "../../../core/constantes";
import {useNavigate} from "react-router-dom";
import {callApiAdministrarRelanzarAnalisisConfig, callApiAdministrarRelanzarConfig} from "../../../core/restUtils";
import MenuAdministracion from "../../../component/menu/administrador/menu";

export default function AdminHome() {

    const [data, setData] = useState();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [values, setValues] = React.useState({
        fechaAnalisis: "",
        fechaRelanzar: "",
        envioNotificacion: true
    });

    useEffect(() => {
        setLoading(true);

        // Comprobar datos redirect en sesion
        if (localStorage.getItem(SESION_REDIRECT_SESION) != null) {
            navigate(localStorage.getItem(SESION_REDIRECT_SESION));
        }
        setLoading(false);
    }, []);

    function handleChange(evt) {
        /*
          evt.target es el elemento que ejecuto el evento
          name identifica el input y value describe el valor actual
        */
        const { target } = evt;
        const { name, value } = target;

        /*
          Este snippet:
          1. Clona el estado actual
          2. Reemplaza solo el valor del
             input que ejecutó el evento
        */
        const newValues = {
            ...values,
            [name]: value,
        };

        // Sincroniza el estado de nuevo
        setValues(newValues);
    }

    const relanzarConfigByDate = (event) => {
        setLoading(true);
        event.preventDefault();
        relanzarBusquedaPorFecha();
    };

    const relanzarAnalisisByDate = (event) => {
        setLoading(true);
        event.preventDefault();
        relanzarAnalisisPorFecha();
    };

    const relanzarAnalisisPorFecha = async () => {
        try {
            await callApiAdministrarRelanzarAnalisisConfig(values.fechaAnalisis)
            setLoading(false);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const relanzarBusquedaPorFecha = async () => {
        try {
            setLoading(false);
            await callApiAdministrarRelanzarConfig(values.fechaRelanzar, values.envioNotificacion)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const renderHome = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return navigate(URL_ADMIN_HOME_LOGIN);

        return (
            <>
                <div className="content">
                    <h1>Benvido</h1>
                    <p>Esta é a página principal.</p>
                    <div>
                        <h1>Relanzar configuración fecha</h1>
                        <div className="buscador" key="containerBuscadorPorFecha">
                            <label htmlFor="search-date">Relanzar por fecha:</label>
                            <input type="date" key="search-date" name="fechaRelanzar" value={values.fechaRelanzar}
                                   onChange={handleChange}/>
                            <label htmlFor="envioNotificacionId">Envio notificación:</label>
                            <select name="envioNotificacion" id="envioNotificacionId" onChange={handleChange}>
                                <option value="true">SI</option>
                                <option value="false">NON</option>
                            </select>

                            <button onClick={relanzarConfigByDate}>Relanzar</button>
                        </div>
                    </div>

                    <div>
                        <h1>Relanzar analisis fecha</h1>
                        <div className="buscador" key="containerBuscadorPorFecha">
                            <label htmlFor="search-date">Relanzar por fecha:</label>
                            <input type="date" key="search-date" name="fechaAnalisis" value={values.fechaAnalisis}
                                   onChange={handleChange}/>
                            <button onClick={relanzarAnalisisByDate}>Relanzar</button>
                        </div>
                    </div>

                </div>
            </>
        );

    }

    return (
        <>
            {<MenuAdministracion/>}
            {renderHome()}
            {<Footer/>}
        </>
    );

}
