import './etiquetas.css';
import Menu from "../../component/menu/menu";
import Footer from "../../component/footer/footer";
import React, {useEffect, useState} from "react";
import { callApiOposConsultarEtiquetas} from "../../core/restUtils";

export default function Etiquetas() {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getDataOposPorFecha = async () => {
            try {
                setLoading(true);
                const result = await callApiOposConsultarEtiquetas()
                setPosts(result);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        getDataOposPorFecha();
    }, []);

    const renderEtiquetas = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        return posts.map((item, index) => (
            <>
            <a href={'/public/?etiquetaBusqueda=' + encodeURI(item)}>
                <div className="etiqueta" key={index}>
                    {item}
                </div>
            </a>
            </>
        ));
    };

    return (
        <>
            {<Menu/>}
            <div className="etiquetas-container">
                {renderEtiquetas()}
            </div>
            {<Footer/>}
        </>
    );
}