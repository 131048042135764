import {
  BrowserRouter as Router,
  Routes, Route
} from 'react-router-dom'
import Home from "./page/home/home";
import Etiquetas from "./page/etiquetas/etiquetas";
import Bop from "./page/bop/bop";
import AdminLogin from "./page/admin/login/login";
import CallbackAdminLogin from "./page/admin/callback/callbackAdminLogin";
import AdminHome from "./page/admin/home/home";
import CerrarSesionAdmin from "./page/admin/cerrarSesion/cerrarSesionAdmin";
import AdministrarEtiquetasAdmin from "./page/admin/etiquetas/etiquetas";
import AdministrarBusquedaAdmin from "./page/admin/busqueda/busqueda";
import AdministrarBopAdmin from "./page/admin/bop/bop";
import AdministrarOposAdmin from "./page/admin/opos/opos";
import {AuthProvider} from "./auth/AuthProvider";
import PrivateRoute from "./auth/PrivateRoute";
import AdministrarNotificacionesAdmin from "./page/admin/notificaciones/notificaciones";
import AdministrarEstadisticasAdmin from "./page/admin/estadisticas/estadisticas";
import AdministrarModificarOposAdmin from "./page/admin/modificarOpo/ModificarOpo";
import Oposicion from "./page/oposicion/oposicion";
import AdministrarCrearProcesoOposicionAdmin from "./page/admin/administrarProcesoOposicion/crearProcesoOposicion";
import AdministrarVerListadoProcesoOposAdmin from "./page/admin/listadoProcesoOposicions/listadoProcesoOposicion";
import AdministrarModificarProcesoOposicionAdmin from "./page/admin/administrarProcesoOposicion/modificarProcesoOposicion";
import AdministrarAddfaseProcesoAdmin from "./page/admin/administrarProcesoOposicion/addFaseProceso";

const App = () => {

  return (
      <Router>
          <AuthProvider>
            <Routes>
                <Route path="/public/" element={<Home/>} />
                <Route path="/public/home" element={<Home/>} />
                <Route path="/public/oposicion/:opo" element={<Oposicion/>} />
                <Route path="/public/etiquetas" element={<Etiquetas/>} />
                <Route path="/public/bop" element={<Bop/>} />
                <Route path="/public/admin/login" element={<AdminLogin/>} />
                <Route path="/public/admin/callback" element={<CallbackAdminLogin/>} />
                <Route element={<PrivateRoute />}>
                    <Route path="/public/admin/" element={<AdminHome/>} />
                    <Route path="/public/admin/home" element={<AdminHome/>} />
                    <Route path="/public/admin/bop" element={<AdministrarBopAdmin/>} />
                    <Route path="/public/admin/opos" element={<AdministrarOposAdmin/>} />
                    <Route path="/public/admin/modificar/opo" element={<AdministrarModificarOposAdmin/>} />
                    <Route path="/public/admin/crearProceso/opo" element={<AdministrarCrearProcesoOposicionAdmin/>} />
                    <Route path="/public/admin/modificarProceso/opo" element={<AdministrarModificarProcesoOposicionAdmin/>} />
                    <Route path="/public/admin/verProceso/opo" element={<AdministrarVerListadoProcesoOposAdmin/>} />
                    <Route path="/public/admin/addFaseProceso/opo" element={<AdministrarAddfaseProcesoAdmin/>} />
                    <Route path="/public/admin/configurarBusqueda" element={<AdministrarBusquedaAdmin/>} />
                    <Route path="/public/admin/etiquetas" element={<AdministrarEtiquetasAdmin/>} />
                    <Route path="/public/admin/notificaciones" element={<AdministrarNotificacionesAdmin/>} />
                    <Route path="/public/admin/estadisticas" element={<AdministrarEstadisticasAdmin/>} />
                    <Route path="/public/admin/cerrarSesion" element={<CerrarSesionAdmin/>} />
                </Route>
            </Routes>
          </AuthProvider>

      </Router>
  )
}

export default App;