import Menu from "../../component/menu/menu";
import './home.css';
import Footer from "../../component/footer/footer";
import React, {useState, useEffect, useCallback} from 'react';
import {
    callApiOposBuscarPorEtiqueta,
    callApiOposBuscarPorTexto,
    callApiOposConsultaPorDiasAtras,
    callApiOposConsultaPorFecha, callApiOposConsultaPorFechaAndBop
} from "../../core/restUtils";
import {formatDate} from "../../core/utiles";

export default function Home() {

    const [posts, setPosts] = useState([]);
    const [data, setData] = useState();
    const [textoFiltrado, setTextoFiltrado] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useCallback(() => {

    }, []);

    useEffect(() => {
        // recuperamos el querystring
        const querystring = window.location.search
        console.log(querystring)

        // usando el querystring, creamos un objeto del tipo URLSearchParams
        const params = new URLSearchParams(querystring)
        console.log(params)

        const getDataOposPorDiasAtras = async () => {
            try {
                setLoading(true);
                const result = await callApiOposConsultaPorDiasAtras(7)
                setPosts(result);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        const getDataOposPorFechaParam = async (fecha) => {
            try {
                setLoading(true);
                const result = await callApiOposConsultaPorFecha(fecha)
                setPosts(result);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        const getDataOposPorFechaAndBopParam = async (fecha, bop) => {
            try {
                setLoading(true);
                const result = await callApiOposConsultaPorFechaAndBop(fecha, bop)
                setPosts(result);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        const getDataOposPorEtiqueta = async (textoEtiqueta) => {
            try {
                setLoading(true);
                const result = await callApiOposBuscarPorEtiqueta(textoEtiqueta)
                setPosts(result);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        if (params != null && params.get("etiquetaBusqueda") != null) {
            getDataOposPorEtiqueta(params.get("etiquetaBusqueda"));
        } else if (params != null && params.get("fecha") != null && params.get("bop") != null) {
            getDataOposPorFechaAndBopParam(params.get("fecha"), params.get("bop"));
        }else if (params != null && params.get("fecha") != null) {
            getDataOposPorFechaParam(params.get("fecha"));
        } else {
            // Buscar semana anterior
            getDataOposPorDiasAtras();
        }
    }, []);

   const renderOpos = () => {

       if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
       if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

       return posts.map((item, index) => {

           // Formatear a data
           const fechaFormateada = formatDate(item.fechaBop[2], item.fechaBop[1], item.fechaBop[0])

           return (
               <>
                   <ul>
                       <div className="oposicion" id={item.uuid}>
                           <h2>Oposición: {item.organizacion}</h2>
                           <h3>Fecha BOP: {fechaFormateada}</h3>
                           <h3>BOP: {item.bop}</h3>
                           <p><strong>Descripción:</strong> {item.descripcion}</p>
                           <p><a href={item.urlDetalle} rel="noopener" target="_blank">Más información</a></p>
                           <div className="containeretiquetas">
                               {item.etiquetas.map((eti, index) => (
                                   <a href={'?etiquetaBusqueda=' + eti}>
                                       <div className="etiquetas">
                                           <p>{eti}</p>
                                       </div>
                                   </a>
                                   ))}
                           </div>
                       </div>
                   </ul>
               </>
           )
       });
   };

    const filterByDate = (event) => {
        event.preventDefault();
        const getDataOposPorFecha = async () => {
            try {
                setLoading(true);
                const result = await callApiOposConsultaPorFecha(data)
                setPosts(result);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        getDataOposPorFecha();
    };

    const filterByText = (event) => {
        event.preventDefault();
        const getDataOposPorTexto = async () => {
            try {
                setLoading(true);
                const result = await callApiOposBuscarPorTexto(textoFiltrado)
                setPosts(result);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        getDataOposPorTexto();
    };

    const handleInputDateChange = (event) => {
        setData(event.target.value);
    };

    const handleInputTextChange = (event) => {
        setTextoFiltrado(event.target.value);
    };

    return (
        <>
            {<Footer/>}
            {<Menu/>}
            <div className="search-container">
                <div className="buscador" key="containerBuscadorPorFecha">
                    <label htmlFor="search-date">Buscar por fecha:</label>
                    <input type="date" key="search-date" name="search-date" value={data} onChange={handleInputDateChange}/>
                    <button onClick={filterByDate}>Buscar</button>
                </div>
                <div className="buscador" key="containerBuscadorPorCadena">
                    <label htmlFor="search-text">Buscar por texto:</label>
                    <input type="text" key="search-text" name="search-text" value={textoFiltrado} onChange={handleInputTextChange}/>
                    <button onClick={filterByText}>Buscar</button>
                </div>
            </div>
            <div id="convocatorias" className="container">
                {renderOpos()}
            </div>
            {<Footer/>}
        </>
    );
}