import Menu from "../../../component/menu/menu";
import Footer from "../../../component/footer/footer";
import './login.css';
import React, {useEffect, useState} from "react";
import {
    OAUTH_CLIENT_ID,
    OAUTH_REDIRECT_URL,
    OAUTH_RESPONSE_TYPE,
    SESION_REDIRECT_SESION,
    SESION_TIME_TOKEN_JWT
} from "../../../core/constantes";

export default function AdminLogin() {

    const [oauthUrl, setOauthUrl] = useState([]);

    useEffect(() => {
        const client_id = OAUTH_CLIENT_ID;  // Reemplaza con tu client_id
        const redirect_uri = OAUTH_REDIRECT_URL;  // Reemplaza con tu URI de redireccionamiento
        const response_type = OAUTH_RESPONSE_TYPE;

        // recuperamos el querystring
        const querystring = window.location.search
        console.log(querystring)

        // usando el querystring, creamos un objeto del tipo URLSearchParams
        const params = new URLSearchParams(querystring)
        console.log(params)

        if (params != null && params.get("redirect_postlogin") != null) {
            localStorage.setItem(SESION_REDIRECT_SESION, params.get("redirect_postlogin"));
        }

        setOauthUrl(`https://oauth.reyesi.com.es/realms/ReyesI/protocol/openid-connect/auth?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}`);

    }, []);

    const renderAdminLogin = () => {
        return (
            <>
                <div className="login-container">
                    <h2>Iniciar Sesión</h2>
                    <a className="login-btn" href={oauthUrl} id="loginBtn">Iniciar sesión</a>
                </div>
            </>
        );
    }

    return (
        <>
            {<Menu/>}
                <div>
                    {renderAdminLogin()}
                </div>
            {<Footer/>}
        </>
    );
}